<template>
        <el-dialog title="Bunuri preluate" :visible.sync="showPopup" class="my-dialog-class" >
        <el-form label-position="top" :inline="false" :rules="rules" label-width="100%"  :model='selectedObject'  ref='my-form' @submit.prevent="save" v-loading="loadingVisible" >
            <el-row :gutter="15" >
                
            <el-col :md='8'>
                <el-form-item label='Dosar' >
                     <el-select v-model='selectedObject.Dosar'  class='full-width'>        
                         <el-option v-for='item in Info.dosare' :key="'dosare' + item.Id" :label='item.NumarDosar' :value='item.Id'></el-option>
                    </el-select>
                </el-form-item>
            </el-col>
            <el-col :md='8'>
                <el-form-item label='Tip bun' >
                     <el-select class='full-width' v-model='selectedObject.TipBun' ><el-option label='Toate' value='-1'></el-option><el-option label='Corporale - Active fixe' value='Corporale - Active fixe'></el-option><el-option label='Corporale - Imobile' value='Corporale - Imobile'></el-option><el-option label='Corporale - Bunuri de inventar' value='Corporale - Bunuri de inventar'></el-option><el-option label='Necorporale' value='Necorporale'></el-option>
                     </el-select>
                </el-form-item>
            </el-col>
            <el-col :md='8'>
                <el-form-item label='Numar inventar' >
                    <el-input  class='full-width' v-model='selectedObject.NrInventar' />
                </el-form-item>
            </el-col>
            <el-col :md='8'>
                <el-form-item label='Nume' >
                    <el-input  class='full-width' v-model='selectedObject.Nume' />
                </el-form-item>
            </el-col>
            <el-col :md='8'>
                <el-form-item label='Cantitate' >
                    <el-input-number class='full-width' v-model='selectedObject.Cantitate' :precision='0' :max='999999'></el-input-number>
                </el-form-item>
            </el-col>
            <el-col :md='8'>
                <el-form-item label='Descriere' >
                    <el-input  class='full-width' v-model='selectedObject.Descriere' />
                </el-form-item>
            </el-col>
            <el-col :md='24'>
                <el-form-item label='Poza preluare' >
                <el-upload
                        class='my-uploader'
                        :action="baseUrl + 'Bunuri_preluate/upload_PozaPreluare'"
                        :show-file-list='false'
                        :on-success='handleUploadSuccess_PozaPreluare'>
                            <i class='el-icon-plus my-uploader-icon'></i>
                        </el-upload>       <img v-if='selectedObject.uploadPreview_PozaPreluare' :src='selectedObject.uploadPreview_PozaPreluare' > 
                </el-form-item>
            </el-col>            </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer" >
            <el-button @click="showPopup=false"     > Renunta  </el-button>
            <el-button type="primary" @click="save" > Salveaza </el-button>
        </span>
    </el-dialog>
</template>

<script>
    import settings from "@/backend/LocalSettings";
    import BasePage from '@/pages/BasePage';

    export default {
        name: "Bunuri_preluate_dialog",
        extends: BasePage,
        data () {
            return {
                baseUrl :'',
                showPopup: false,
                mode: 'add',
                selectedObject: {
                    Dosar: '' , TipBun: '' , NrInventar: '' , Nume: '' , Cantitate: '1' , Descriere: '' , PozaPreluare: '' , uploadPreview_PozaPreluare : '' ,                 },
                Info:{
                    dosare: [],                 },
                rules: {
                    //   Nume: [ { required: true, message: "Campul este obligatoriu" } ]
                    Dosar: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    TipBun: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    NrInventar: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    Nume: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    Cantitate: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    Descriere: [ { required: false, message: 'Campul este obligatoriu' } ], 
                    PozaPreluare: [ { required: false, message: 'Campul este obligatoriu' } ], 
                }
            }
        },
        methods: {
            show_me: async function( id ) {
                this.showPopup        = true;
                if( id == null )
                {
                    this.mode = "add";
                }
                else
                {
                    this.mode             = "edit";
                    var            result = await this.post("bunuri_preluate/get_info_item_dialog", { id: id } );
                    this.selectedObject   = result.Item;
                }
            },
            async get_info(){
                var response = await this.post("bunuri_preluate/get_info_for_dialog" );
                this.Info.dosare = response.dosare;            },
             handleUploadSuccess_PozaPreluare(response){
                    this.selectedObject.PozaPreluare = response.FileName;
                    this.selectedObject.uploadPreview_PozaPreluare  = response.Url;
                },
                    save: async function() {
                this.$refs['my-form'].validate( async(valid) => {
                    if (valid)
                    {
                        await this.post("bunuri_preluate/save", { mode: this.mode, object: this.selectedObject } );
                        this.showPopup = false;
                        this.$emit("save");
                    }
                });
            }
        },
        mounted: function() {
            this.baseUrl = settings.BASE_URL;
            this.get_info();
        }
    };
</script>

<style lang="less" scoped>
    .full-width {
        width: 100%;
    }

</style>
<template>
    <base-page>
        <titlu-pagina Titlu="Bunuri preluate" @on_add_clicked="show_dialog()" :AdaugaVisible='true' />
        <el-card style='margin:5px 0px 5px 0px'>
            <div slot="header" class="clearfix">
                <strong> Filtre </strong>
            </div>
            <div class="filtre">
                <el-form @submit.prevent.native='refresh_info()'>
                    <el-row :gutter="20">
                        <el-col :span='4'>
                            <el-form-item label='Dosar' >
                                <el-select class='full-width' v-model='Filters.IdDosar' ><el-option label='Toate' value='-1'></el-option>
                                    <el-option v-for='item in Info.dosare' :key="'dosare' + item.Id" :label='item.NumarDosar' :value='item.Id'></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span='4'>
                            <el-form-item label='Tip bun' >
                                <el-select class='full-width' v-model='Filters.TipBun' ><el-option label='Toate' value='-1'></el-option><el-option label='Corporale - Active fixe' value='Corporale - Active fixe'></el-option><el-option label='Corporale - Imobile' value='Corporale - Imobile'></el-option><el-option label='Corporale - Bunuri de inventar' value='Corporale - Bunuri de inventar'></el-option><el-option label='Necorporale' value='Necorporale'></el-option><el-option label='' value=''></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span='4'>
                            <el-form-item label='Numar inventar' >
                                <el-input v-model='Filters.NrInventar' />
                            </el-form-item>
                        </el-col>
                        <el-col :span='4'>
                            <el-form-item label='Nume' >
                                <el-input v-model='Filters.Nume' />
                            </el-form-item>
                        </el-col>
                        <el-col :span='4'>
                            <el-form-item label='Status' >
                                <el-select class='full-width' v-model='Filters.Status' ><el-option label='Toate' value='-1'></el-option><el-option label='Preluat' value='Preluat'></el-option><el-option label='Casat' value='Casat'></el-option><el-option label='Furat' value='Furat'></el-option><el-option label='Defect' value='Defect'></el-option><el-option label='In asteptare HG pt imobile' value='InAsteptareHG pt imobile'></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>                        
                        <el-col :md='24' >
                            <el-button type='primary' native-type='submit' @click='refresh_info()'> Aplica </el-button>
                        </el-col>
                    </el-row>
                </el-form>
                </div>
        </el-card>

        <el-table :data="Results" >
            <el-table-column prop='IdDosar' label='Dosar'></el-table-column><el-table-column prop='TipBun' label='Tip bun'></el-table-column><el-table-column prop='NrInventar' label='Numar inventar'></el-table-column>
            <el-table-column prop='Nume' label='Nume'></el-table-column>
            <el-table-column prop='Cantitate' label='Cantitate'></el-table-column>
            <el-table-column prop='Descriere' label='Descriere'></el-table-column><el-table-column prop='Status' label='Status'></el-table-column><el-table-column fixed="right" label="Actiuni" width="200px" >
                <template slot-scope="scope" >
                    <el-tooltip content="Modificare">
                        <el-button type="primary" icon="el-icon-edit"  circle @click="show_dialog(scope.row.Id)" />
                    </el-tooltip>
                    <el-tooltip content="Sterge" >
                        <el-button type="danger" icon="el-icon-delete" circle @click="delete_item(scope.row)" />
                    </el-tooltip>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination @size-change="refresh_info" @current-change= "refresh_info" :page-size.sync="PaginationInfo.PerPage" :current-page.sync="PaginationInfo.Page" :total="PaginationInfo.RowCount" layout="pager" />
        <Bunuri-preluate-dialog ref='dlg' @save="refresh_info()" />
    </base-page>
</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";
import Bunuri_preluate_dialog from '@/pages/bunuri_preluate/Bunuri_preluate_dialog.vue';
import TitluPagina from '@/widgets/TitluPagina';

export default {
    name: "bunuri_preluate",
    extends: BasePage,
    components: {
        'base-page': BasePage,
        'Bunuri-preluate-dialog': Bunuri_preluate_dialog,
        'titlu-pagina': TitluPagina
    },
    data () {
        return {
            Results: [],
            base_url: '',
            Info: {
                dosare: [],             },
            Filters: {
                IdDosar: '-1' , TipBun: '-1' , NrInventar: '' , Nume: '' , Status: '-1' ,             },
            OrderBy: { },
            PaginationInfo: { Page: 1, PerPage: 50, RowCount: 0, PageSizes: [10, 25, 50, 100, 200] },
        }
    },
    methods: {

        async get_info(){
            var response        = await this.post("bunuri_preluate/get_info" );
            this.Info.dosare = response.dosare;

            // if( this.Filters.IdDosar.length > 0 )
            //     this.Filters.IdDosar = this.Info.dosare[0].Id;

            this.refresh_info();
        },

        async refresh_info(){
            var response        = await this.post("bunuri_preluate/index", { Filters: this.Filters, OrderBy: this.OrderBy, PaginationInfo: this.PaginationInfo } );
            this.Results        = response.Results;
            this.PaginationInfo = response.PaginationInfo;
            //
            this.select_menu_item('bunuri_preluate');
        },
        reset(){
            this.Filters = {
                IdDosar: '-1' , TipBun: '-1' , NrInventar: '' , Nume: '' , Status: '-1' ,             };
            this.refresh_info();
        },


        async delete_item( item ){
            var confirm =  await this.$confirm(`Sunteti sigur ?`, 'Warning');
            if( confirm )
            {
                await this.post("bunuri_preluate/delete_item", { id: item.Id } );
                this.refresh_info();
            }
        },

        show_dialog(id){
            this.$refs.dlg.show_me(id);
        }
    },
    mounted(){
        this.base_url = settings.BASE_URL;
        this.get_info();
    }
};
</script>

<style lang="less" scoped>

    .top50{
        margin-top: 20px;
    }

    .filtre{
        .el-input-number
        {
            width: 100% !important;
        }
    }

</style>
